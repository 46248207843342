import {AFD, getLS} from 'devegram-javascript-util';

const baseUrl = '/xapi/individual/user';
const baseUrl2 = '/xapi/individual/UserModule';
const moduleAjaxService = 'AMS'
const userAPI = {
    namespaced: true,
    state: {
        version: 0.1,
        token: null,
        title: null
    },
    actions: {
        async getUsersDropDownList(context, params) {
            let query = ''
            if (params.hasOwnProperty('valueKey')) {
                query = '?value_key=' + params.valueKey
            }
            let api = {
                verb: 'get',
                service: moduleAjaxService,
                url: baseUrl + '/dropDownList',
                data: null
            };

            return new Promise((resolve, reject) => {
                context.dispatch('ajaxManager/api', api, {root: true}).then(r => {
                    resolve(r.data);
                }).catch(e => {
                    reject(e);
                });
            })
        },
        async create(context, params) {
            let api = {
                verb: 'post',
                service: moduleAjaxService,
                url: baseUrl + '/store',
                data: AFD(params.data)
            };

            return new Promise((resolve, reject) => {
                context.dispatch('ajaxManager/api', api, {root: true}).then(r => {
                    resolve(r);
                }).catch(e => {
                    reject(e);
                })
            })
        },
        async giveUserModules(context, params) {
            let api = {
                verb: 'post',
                service: moduleAjaxService,
                url: baseUrl2 + '/giveUserModules',
                data: AFD(params.data)
            };

            return new Promise((resolve, reject) => {
                context.dispatch('ajaxManager/api', api, {root: true}).then(r => {
                    resolve(r.data);
                }).catch(e => {
                    reject(e);
                })
            })
        },
        async removeUserModules(context, params) {
            let api = {
                verb: 'post',
                service: moduleAjaxService,
                url: baseUrl2 + '/removeUserModules',
                data: AFD(params.data)
            };

            return new Promise((resolve, reject) => {
                context.dispatch('ajaxManager/api', api, {root: true}).then(r => {
                    resolve(r.data);
                }).catch(e => {
                    reject(e);
                })
            })
        },
        async userHasModule(context, params) {
            let api = {
                verb: 'get',
                service: moduleAjaxService,
                url: baseUrl2 + '/userHasModule?user_id=' + params.id + '&module_name=' + params.module
            };

            return new Promise((resolve, reject) => {
                context.dispatch('ajaxManager/api', api, {root: true}).then(r => {
                    resolve(r.data);
                }).catch(e => {
                    reject(e);
                });
            })
        },
        async getUserPermissions(context, params) {
            let api = {
                verb: 'get',
                server: 'AMC',
                url: baseUrl + '/getUserPermissions'
            };

            return new Promise((resolve, reject) => {
                context.dispatch('ajaxManager/api', api, {root: true}).then(r => {
                    let data = r.data;
                    let list = [];
                    for (let i in data) {
                        list.push(data[i]);
                    }

                    resolve(list);
                }).catch(e => {
                    reject(e);
                });
            })
        },
        async verify(context, params) {
            let verifyAPI = {
                verb: 'post',
                service: 'AMS',
                url: baseUrl + '/verify',
                headers: {}
            };

            return new Promise((resolve, reject) => {
                context.dispatch('ajaxManager/api', verifyAPI, {root: true}).then((r) => {
                    context.dispatch('App-User-state/setProfileInfo', getLS('user'), {root: true}).then(() => {
                        resolve(r)
                    })
                }).catch(e => {
                    reject(e);
                });
                ;
            })
        },
        async changeLoggedInUserPassword(context, params) {
            let api = {
                verb: 'post',
                service: moduleAjaxService,
                url: baseUrl + '/changeLoggedInUserPassword',
                data: AFD(params.data)
            };

            return new Promise((resolve, reject) => {
                context.dispatch('ajaxManager/api', api, {root: true}).then(r => {
                    resolve(r);
                }).catch(e => {
                    reject(e);
                })
            })
        },
        async updateLoggedInProfile(context, params) {
            let api = {
                verb: 'post',
                service: moduleAjaxService,
                url: baseUrl + '/updateLoggedInProfile',
                data: AFD(params.data)
            };

            return new Promise((resolve, reject) => {
                context.dispatch('ajaxManager/api', api, {root: true}).then(r => {
                    resolve(r);
                }).catch(e => {
                    reject(e);
                })
            })
        },
        async update(context, params) {
            let api = {
                verb: 'post',
                service: moduleAjaxService,
                url: baseUrl + '/update/' + params.id,
                data: AFD(params.data)
            };

            return new Promise((resolve, reject) => {
                context.dispatch('ajaxManager/api', api, {root: true}).then(r => {
                    resolve(r);
                }).catch(e => {
                    reject(e);
                })
            })
        },
        async list(context, params) {
            let page = (params.page) ? params.page : 1
            let profileId = (params.hasOwnProperty('profileId'))? "&profile_id=" + encodeURIComponent(params.profileId) : ''
            let search = (params.hasOwnProperty('search'))? "&search=" + encodeURIComponent(params.search) : ''
            let api = {
                verb: 'get',
                service: moduleAjaxService,
                url: baseUrl + '/list?page=' + page + search +  profileId,
                data: null
            };

            return new Promise((resolve, reject) => {
                context.dispatch('ajaxManager/api', api, {root: true}).then(r => {
                    resolve(r.data);
                }).catch(e => {
                    reject(e);
                });
            })
        },
        async getDropDownList(context, params) {
            let api = {
                verb: 'get',
                service: moduleAjaxService,
                url: baseUrl + '/dropDownList',
                data: null
            };

            return new Promise((resolve, reject) => {
                context.dispatch('ajaxManager/api', api, {root: true}).then(r => {
                    resolve(r.data);
                }).catch(e => {
                    reject(e);
                });
            })
        },
        async remove(context, params) {
            let api = {
                verb: 'post',
                service: moduleAjaxService,
                url: baseUrl + '/remove/' + params.id
            };

            return new Promise((resolve, reject) => {
                context.dispatch('ajaxManager/api', api, {root: true}).then(r => {
                    resolve(r);
                }).catch(e => {
                    reject(e);
                });
            })
        },
        async login(context, params) {
            let fd = AFD({
                username: params.identityPassport,
                password: params.secret
            });

            return new Promise((resolve, reject) => {
                $store.dispatch('ajaxManager/api', {
                    service: 'AMS',
                    verb: 'post',
                    url: '/xapi/individual/user/login',
                    data: fd,
                    noToken: true
                }).then(r => {
                    context.dispatch('App-User-state/setProfileInfo', r.data, {root: true}).then(() => {
                        let userBackendModules = r.data.modules
                        let localModules = $store.state['App-User-state'].modules
                        let useLocalModules = false
                        $jkms.bootNamespacesModules((useLocalModules)? localModules : userBackendModules).then(() => {
                            context.dispatch('ajaxManager/setToken', {
                                token: r.data.token
                            }, {root: true}).then(() => {
                                resolve(true);
                            })
                        })
                    })
                }).catch(e => {
                    reject(e);
                });
            });
        }
    }
};

export default userAPI
